// helpers
import { Masks } from '@/Helpers/Mask';
import { validateCPF } from '@/Helpers/Misc';
import { validateMsisdn } from '@/Helpers/Msisdn';
import { Toast as toast } from '@/Helpers/Toast';

export default {
  name: 'UserAccount',

	data() {
		return {
			loading: false,

			form: {
				msisdn: '',
				email: '',
				name: '',
				method: 'cpf'
			},

			errors: {
				msisdn: false,
				email: false,
				name: false
			},

			// helpers
			Masks: Masks
		};
	},

	methods: {
		submit: function() {
			if ((this.errors.cpf || this.errors.msisdn) && this.errors.email) return;

			this.loading = true;

			setTimeout(() => {
				this.loading = false;
				toast('success', 'Seus dados foram atualizados com sucesso.');
			}, 3500);
		},
		validate: function(type, value) {
			const val = Masks.unset(value);

			if (!val) return;

			if (type === 'cpf') {
				this.errors.cpf = val.length > 10 ? !validateCPF(val) : false;
			} else if (type === 'phone') {
				this.errors.msisdn = val.length > 10 ? !validateMsisdn(val).status : false;
			}
		}
	}
};
