var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"_user:form"},[_c('FormulateForm',{on:{"submit":_vm.submit}},[_c('FormulateInput',{staticClass:"form:group",attrs:{"element-class":"form:control","type":"tel","label":"NOME","placeholder":"João da Silva","name":"name","validation":"bail|required|min:2","validation-messages":{
        required: 'Campo obrigatório',
        min: 'Preencha o campo corretamente',
      }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('FormulateInput',{staticClass:"form:group",attrs:{"element-class":"form:control","type":"text","label":"EMAIL","placeholder":"email@email.com","name":"email","validation":"bail|required|email","validation-messages":{
        required: 'Campo obrigatório',
        email: 'O Email informado é inválido',
      }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"user:options"},[_c('a',{staticClass:"_btn _btn:green",attrs:{"href":"/user/account/method/cpf"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.form.method = 'cpf'}}},[_vm._v("CPF")]),_c('a',{staticClass:"_btn _btn:yellow",attrs:{"href":"/user/account/method/phone"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.form.method = 'phone'}}},[_vm._v("Celular")])]),(_vm.form.method === 'cpf')?_c('FormulateInput',{staticClass:"form:group",attrs:{"element-class":"form:control","type":"text","placeholder":"___.___.___-__","name":"cpf","error":this.errors.cpf ? 'O CPF informado é inválido' : null,"validation":"bail|required|cpf|min:14|max:14","validation-rules":{
        cpf: function (ref) {
              var value = ref.value;

              return (this$1.form.cpf = _vm.Masks.cpf(value));
},
      },"validation-messages":{
        required: 'Campo obrigatório',
        cpf: 'Preencha o campo corretamente',
        min: 'Preencha o campo corretamente',
        max: 'Preencha o campo corretamente',
      }},on:{"input":function($event){return _vm.validate('cpf', _vm.form.cpf)}},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}):_vm._e(),(_vm.form.method === 'phone')?_c('FormulateInput',{staticClass:"form:group",attrs:{"element-class":"form:control","type":"tel","placeholder":"( __ ) _____.____","name":"msisdn","error":this.errors.msisdn ? 'O Número de telefone é inválido' : null,"validation":"bail|required|msisdn|min:17|max:17","validation-rules":{
        msisdn: function (ref) {
              var value = ref.value;

              return (this$1.form.msisdn = _vm.Masks.msisdn(value));
},
      },"validation-messages":{
        required: 'Campo obrigatório',
        msisdn: 'Preencha o campo corretamente',
        min: 'Preencha o campo corretamente',
        max: 'Preencha o campo corretamente',
      }},on:{"input":function($event){return _vm.validate('phone', _vm.form.msisdn)}},model:{value:(_vm.form.msisdn),callback:function ($$v) {_vm.$set(_vm.form, "msisdn", $$v)},expression:"form.msisdn"}}):_vm._e(),_c('button',{staticClass:"_btn _btn:grey-900",class:_vm.loading ? '_btn:disabled _btn:loading' : '',attrs:{"type":"submit"}},[(this.loading)?_c('span'):_c('span',[_vm._v("Salvar")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }